import { Box, Flex, Text } from "flicket-ui";
import { formatDate } from "~lib";
import { Event } from "~graphql/sdk";

export const MenuEventHeader = ({ event }: { event: Event }) => (
  <Box
    display={{ _: "flex", md: "none" }}
    position="absolute"
    bottom="16px"
    left="16px"
    zIndex={2}
    maxW="calc(100% - 32px)"
    flexDir="column"
  >
    <Text
      fontSize={6}
      lineHeight="medium"
      fontWeight="heavy"
      color="white"
      mb="1/2"
      ellipsis
    >
      {event.title}
    </Text>
    <Flex
      mr={{ _: 2, md: 4 }}
      flexDir="column"
      minW={{ _: "80px", md: "auto" }}
    >
      {event.endDate &&
      formatDate(event.startDate as string, "dd") !==
        formatDate(event.endDate as string, "dd") ? (
        <Text fontSize={1} lineHeight="normal" mb="1/4" color="white">
          {formatDate(event.startDate as string, "dd")} -{" "}
          {formatDate(event.endDate as string, "dd MMM")}
        </Text>
      ) : (
        <Text fontSize={1} lineHeight="normal" mb="1/4" color="white">
          {formatDate(event.startDate as string, "EEEE d MMMM, p")}
        </Text>
      )}
    </Flex>
    <Text fontSize={1} lineHeight="normal" color="white">
      {event.venue?.name}, {event.venue?.address?.city}
    </Text>
  </Box>
);
